<template>
  <div class="cityLocation">
    <breadcrumb icon="el-icon-s-grid"></breadcrumb>
    <city-list @selectedCity="selectedCid"></city-list>
    <div v-if="nodata">没有数据...</div>
    <div v-else class="content">
      <p class="intro" v-html="areaData.intro"></p>
      <div class="table">
        <h2>{{  areaData.table.title  }}</h2>
        <span class="timeSource">
          <span>上传时间：{{  areaData.table.uptime  }}</span>
          <span>|</span>
          <span>数据来源：{{  areaData.table.source  }}</span>
        </span>
        <div class="year">
          <span>{{  areaData.table.year  }}</span>
          <span class="unit">{{  areaData.table.unit  }}</span>
        </div>
        <el-table :data="areaData.table.tabledata" stripe border style="width: 100%">
          <el-table-column prop="diqu" label="地区" width="180">
          </el-table-column>
          <el-table-column prop="zhen" label="镇" width="180">
          </el-table-column>
          <el-table-column prop="jiedao" label="街道">
          </el-table-column>
          <el-table-column prop="juweihui" label="居委会">
          </el-table-column>
          <el-table-column prop="cunweihui" label="村委会">
          </el-table-column>

          <el-table-column prop="tudimianji" label="土地面积(平方公里)">
          </el-table-column>
        </el-table>
        <p class="remarks">{{  areaData.table.remarks  }}</p>
      </div>
      <div class="downbox">


        <download-button size="normal" type="excel" :params="{
          goods_id: 3,
          condition: { city_plate_id: city_plate_id }
        }" text="下载Excel文档"></download-button>

      </div>
    </div>
  </div>
</template>
<script>
import Breadcrumb from '../components/common/Breadcrumb.vue'
import CityList from '../components/cityreserch/CityList.vue'
import { arealayout, areadown } from '../api/cityresearch.js'
import { download } from '../mixins/download'

import DownloadButton from "@/components/down/DownloadButton.vue"
export default {

  data() {
    return {
      nodata: false,
      //下载返回的数据
      // download: {},
      //页面数据
      areaData: {
        intro: "",
        table: {
          tableData: [],

        }
      },
    }
  },
  mixins: [download],
  components: { Breadcrumb, CityList, DownloadButton },
  computed: {
    cname: {
      get() {
        let a = this.$store.state.cname
        return a;
      },
      set(v) {
        this.$store.state.cname = v
      }
    },

 
    city_plate_id: {
      get() {
        return this.$store.state.city_plate_id
      },
      set(v) {
        this.$store.dispatch('changeCityPlateId', v);
      }
    },


    //当前选择的id
    currentArea: {
      get() {
        return this.$store.state.currentArea
      }
    }
  },
  watch: {
    currentArea(newv, oldv) {
      // console.log('this.currentArea',this.currentArea)
      // console.log("watch-currentArea")
      this.getAreaLayout()
    }
  },
  mounted() {
    this.getAreaLayout()
  },
  methods: {


    //获取数据
    getAreaLayout() {
      // console.log('params',this.currentArea)
      if (this.currentArea > 0) {
        arealayout({ city_plate_id: this.currentArea, cateid: 31 }).then(res => {
          // console.log(res)
          if (res.code === 1001) {
            this.nodata = true
          } else {
            this.nodata = false
            this.areaData = res.data
          }
          // console.log( 'arealayout',this.areaData )
        })
      }
      arealayout({ city_plate_id: this.currentArea, cateid: 31 }).then(res => {
        // console.log(res)
        if (res.code === 1001) {
          this.nodata = true
        } else {
          this.nodata = false
          this.areaData = res.data
        }


        // console.log( 'arealayout',this.areaData )
      })
    },



    handledata(obj) {
      obj.pics = obj.pics.map(i => {
        i.status = false;
        return i
      })
      return obj;

    },

    selectedCid(id) {


    }
  }
}
</script>
<style lang="scss">
.cityLocation {
  width: 100%;
  // height:calc(100vh - 80px);
  // background:red;
  color: #333;
  // overflow: scroll;
}

.content {
  height: calc(100vh - 210px);

  overflow: scroll;
  width: 98%;
  margin: 0 auto;

  .intro {
    background: #F0F3F8;
    border-radius: 8px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    // line-height: 20px;
    // padding: 20px;
    // text-indent: 2em;
    // text-align: left;
  }

  .table {
    // width:80%;
  }

  .timeSource {
    background: rgba(40, 167, 225, 0.1);
    border-radius: 2px;
    border: 1px solid rgba(40, 167, 225, 0.2);

    span {
      display: inline-block;
      padding: 4px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #28A7E1;
    }
  }

  .year {
    position: relative;
    margin: 10px 0;

    .unit {
      position: absolute;
      right: 10px;
    }
  }

  .downbox {
    margin: 20px auto 50px auto;
  }

  .remarks {
    text-align: left;
  }


}
</style>  